<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Mutasi Stock Purchasing</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <mutasi-stock-purchasing-grid ref="grid" :key="gridReload" :editClick="editClick"/>
                <mutasi-stock-purchasing-create-form  ref="MutasiStockPurchasingCreateForm" :reloadMutasiStockPurchasingGrid="reloadMutasiStockPurchasingGrid"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import MutasiStockPurchasingGrid from '../MutasiStockPurchasing/Grid/MutasiStockPurchasingGrid.vue';
import MutasiStockPurchasingCreateForm from './Component/MutasiStockPurchasingCreateForm.vue';

export default {
    name: 'MutasiStockPurchasing',
    components: {
        'mutasi-stock-purchasing-grid': MutasiStockPurchasingGrid,        
        'mutasi-stock-purchasing-create-form': MutasiStockPurchasingCreateForm,  
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Mutasi Stock');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.MutasiStockPurchasingCreateForm.addClick();
        },
        editClick(id, view){
            this.$refs.MutasiStockPurchasingCreateForm.editClick(id, view);
        },
        onChange(){
            this.reload();
        },
        reloadMutasiStockPurchasingGrid(){
            this.gridReload++;
            this.statusFilter();
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnReject").on('click', function() {
                    vue.$refs.grid.changeStatus('Reject');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
            })
        },
        
    }
}
</script>

<style scoped>
</style>