<template>
    <div>
        <input hidden type="text" class="form-control" id="MutationId" v-model="MutationId">
        <div class="modal fade" id="MutasiStockModal" tabindex="-1" aria-labelledby="MutasiStockModalLabel" aria-hidden="true" style="overflow:auto;">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="MutasiStockModalLabel" class="font-weight-bold">{{ModalTitle}}{{StorageId}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Gudang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CSelect id="StorageId" class="font-weight-bold" :options="StorageIdData" :value.sync="StorageId" @change=onChangeStorage() />
                                    <label id="errorStorageId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Keterangan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="Keterangan" v-model="Keterangan" class="font-weight-bold" />
                                    <label id="errorKeterangan" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            
                            <CRow class="p-3">
                                <div class="p-3 border rounded" style="width: 100%;">
                                    <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                                    <div class="row float-right pr-3">
                                        <button class="add-button btn btn-primary float-right"
                                            @click="addFormClick()">
                                            <i class="fa fa-plus"></i> Add
                                        </button>
                                    </div>
                                    <hr>

                                    <div v-if="this.FormType == 'Add'">
                                        <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemGridDataSchemaModel"/>

                                        <kendo-grid ref="gridItem"
                                            :data-source-ref="'itemDataSource'"
                                            :editable="this.editTable"
                                            :pageable="true"
                                            :resizable="true"
                                            :filterable="true"
                                            :sortable="true"
                                            >

                                            <kendo-grid-column  :field="'product_code'"
                                                                :title="'Kode Item'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'item_name'"
                                                                :title="'Nama Item'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'stock'"
                                                                :title="'Stock Item'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'uom'"
                                                                :title="'Satuan'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'qty_change'"
                                                                :title="'Perubahan Quantity'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                            <kendo-grid-column  :command="{ text: 'Delete', click: deleteClick }"
                                                                :title="'Action'"
                                                                :width="100"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{style: 'text-align:center;'}"
                                                                :locked="true"
                                                                :lockable="false"></kendo-grid-column>

                                        </kendo-grid>
                                    </div>
                                    <div v-else>
                                        <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemGridDataSchemaModel"/>

                                        <kendo-grid ref="gridItem"
                                            :data-source-ref="'itemDataSource'"
                                            :editable="this.editTable"
                                            :pageable="true"
                                            :resizable="true"
                                            :filterable="true"
                                            :sortable="true"
                                            >

                                            <kendo-grid-column  :field="'product_code'"
                                                                :title="'Kode Item'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'item_name'"
                                                                :title="'Nama Item'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'uom'"
                                                                :title="'Satuan'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'qty_change'"
                                                                :title="'Perubahan Quantity'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'qty'"
                                                                :title="'Quantity Dasar'"
                                                                :width="190"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{ style: 'text-align:right' }"></kendo-grid-column>
                                        </kendo-grid>
                                    </div>
                                </div>
                            </CRow>
                        </div>
                        <br>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import MutasiStockService from '../Script/MutasiStockServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'MutasiStockForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload', 'addItemClick'],
    data: function () {
        return {
            ModalTitle: '',

            MutationId:'',
            StorageId: '',
            StorageIdData: [],

            Keterangan: '',

            Status: '',
            StatusData: [],
            
            ItemGridData: [],
            ItemGridDataSchemaModel: {
                item_id: { type: "string", editable: false},
                product_code: { type: "string", editable: false},
                item_name: { type: "string", editable: false},
                stock: {type: "number", editable:false},
                uom: {type: "string", editable:false},
                qty_change: { type: "number", editable: true, validation: { required: true} },
            },
            editTable: true,
            
            SaveType: '',
            FormType: '',
            Error: 0,
        }
    },
    methods: {
        async addClick(){    
            $(".form-error").css("display", "none");        
            this.ModalTitle = 'Add Mutasi Stock: ';
            document.getElementById('StorageId').disabled = false;
            document.getElementById('Keterangan').readOnly = false;

            this.MutationId = '';
            this.StorageIdData = await MutasiStockService.getStorageId();
            this.StorageId = this.StorageIdData[0].value;

            this.Keterangan = '';

            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            this.editTable = true;
            this.ItemGridData = [];
            
            this.SaveType = 'Add';
            this.FormType = 'Add';
            
            $(".save-button").show();
            $(".add-button").show();
            window.$('#MutasiStockModal').modal('show');
        },
        async editClick(id, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Mutasi Stock: ';
            document.getElementById('StorageId').disabled = true;
            document.getElementById('Keterangan').readOnly = true;

            var data = await MutasiStockService.getMutasiStockQuery(id);
            
            this.MutationId = data.mut_id;
            this.StorageIdData = await MutasiStockService.getStorageId();
            this.StorageId = data.storage_id;

            this.Keterangan = data.mut_reason;

            this.StatusData = await globalfunc.globalDropdown('ddl_status_item_mutation');
            this.Status = data.status;

            this.editTable = false;
            this.ItemGridData = globalfunc.objectToArrayConverter(data.mut_data, "MutasiStock-ItemData");

            this.SaveType = 'Edit';
            this.FormType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Mutasi Stock: ';
            }
            $(".add-button").hide();
            window.$('#MutasiStockModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            var ItemMutasiStockkData = this.$refs.gridItem.kendoWidget().dataSource._data;

            if ( ItemMutasiStockkData.length == 0){
                this.Error++;
                this.$swal("Error", "Data Item harus di isi", "error");
            }

            for (let i = 0; i < ItemMutasiStockkData.length; i++) {
                if (ItemMutasiStockkData[i].qty == 0){
                    this.Error++;
                    this.$swal("Error", "Jumlah Qty Dasar tidak boleh 0", "error");
                }
                else if ((ItemMutasiStockkData[i].stock + ItemMutasiStockkData[i].qty) < 0 ){
                    this.Error++;
                    this.$swal("Error", "Jumlah Stock Item dan Qty Dasar tidak boleh lebih kecil dari 0", "error");
                }
            }
        },
        async saveClick() {
            this.inputValidation();
            var ItemMutasiStockkData = this.$refs.gridItem.kendoWidget().dataSource._data;

            if(this.Error == 0){
                var ItemMutasiStockArray = MutasiStockService.itemMutasiStockConverter(ItemMutasiStockkData);

                const StockMutationData = {
                    storage_id : this.StorageId,
                    mut_reason : this.Keterangan,
                    status : this.Status,
                    mut_data : ItemMutasiStockArray
                }                

                if(this.SaveType == 'Add'){
                    this.$loading(true);
                    
                    const variables = {
                        data : StockMutationData
                    }
                    
                    MutasiStockService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#MutasiStockModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.MutationId,
                        status : this.Status
                    }
                        
                    MutasiStockService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#MutasiStockModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        addFormClick(){
            this.$props.addItemClick(this.StorageId);
        },
        MutasiStockDetailGridData(data){
            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
            var MutasiStockItemData = MutasiStockService.gridDataConverter(gridData);

            var inputData = MutasiStockService.gridDataConverter(data);
            MutasiStockItemData = MutasiStockItemData.concat(inputData);

            this.ItemGridData = Object.values(MutasiStockItemData.reduce((a, c) => (a[`${c.item_id}${c.item_name}`] = c, a), {})); 
        },
        onChangeStorage(){
            this.ItemGridData = [];
        },
        deleteClick: function(e) {
            e.preventDefault();
            var grid = this.$refs.gridItem.kendoWidget();
            var dataItem = grid.dataItem($(e.currentTarget).closest("tr"));
            
            var gridItem = grid.dataSource._data;
            var itemData = this.$globalfunc.objectToArrayConverter(gridItem, "MutasiStock-ItemData");
            var index = itemData.findIndex((obj => obj.item_id == dataItem.item_id));

            itemData.splice(index, 1);
            this.ItemGridData = itemData;
        }
    }
}
</script>
