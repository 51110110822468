<template>
    <div>
        <div class="modal fade" id="MutasiStockPurchasingCreateModal" tabindex="-1" aria-labelledby="MutasiStockPurchasingCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="MutasiStockPurchasingCreateModalLabel" class="font-weight-bold">Add Mutasi Stock Purchasing</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <mutasi-stock-purchasing-create-grid :key="gridReload" :createClick="createClick"/>
                        <mutasi-stock-purchasing-form ref="MutasiStockPurchasingForm" :reloadMainGrid="reloadMainGrid"/>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>    
import mutasiStockPurchasingCreateGrid from '../Grid/MutasiStockPurchasingCreateGrid.vue';
import MutasiStockPurchasingForm from './MutasiStockPurchasingForm.vue';

export default {
    name: 'MutasiStockPurchasingCreateForm',
    components: {
        'mutasi-stock-purchasing-create-grid': mutasiStockPurchasingCreateGrid,
        'mutasi-stock-purchasing-form': MutasiStockPurchasingForm
    },
    props: ['reloadMutasiStockPurchasingGrid'],
    data: function () {
        return {
           gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.reload();
            window.$('#MutasiStockPurchasingCreateModal').modal('show');
        },
        editClick(id, view){
            window.$('#MutasiStockPurchasingCreateModal').modal('show');
            this.$refs.MutasiStockPurchasingForm.editClick(id, view);
        },
        createClick(data){
            this.$refs.MutasiStockPurchasingForm.addClick(data);
        },
        reloadMainGrid(){
            this.$props.reloadMutasiStockPurchasingGrid();
            window.$('#MutasiStockPurchasingCreateModal').modal('hide');
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>