<template>
    <div>
          <kendo-grid
              ref="grid" 
              :data-source="dataSource"
              :filterable="filterableConfig"
              :sortable="true"
              :pageable="pageableConfig"
              :columns="columns"
              :resizable="true"
              >
          </kendo-grid>
    </div>
  </template>
  
  
  <script>
  import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
  import {globalfunc} from '../../../../shared/GlobalFunction';
  import mutasiStockPurchasingServices from '../Script/MutasiStockPurchasingServices';
  import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
  import { kendo_grid } from '../../../../infrastructure/constant/variable';
  
  export default {
      name: 'MutasiStockPurchasingCreateGrid',
      components: {
          'kendo-grid' : Grid,
          'kendo-grid-column': GridColumn,
      },
      props: ['createClick'],
      data: function () {
          return  {
              dataSource:  new kendo.data.DataSource ({
                      page: 1,
                      pageSize: kendo_grid.default_form_grid_pagesize,
                      serverPaging: false,
                      serverFiltering: false,
                      serverSorting: false,
                      transport: {
                          read: {
                              contentType: "application/json",
                              url: apiBaseUrl,
                              type: "POST",
                              data: function(e) {
                                  return { 
                                      query: mutasiStockPurchasingServices.readMutasiStockPurchasingQuery(),
                                  };
                              },
                              beforeSend: function (req) {
                                  req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                  req.setRequestHeader('Path', window.location.pathname);
                              }
                          },
                          parameterMap: function(options, operation) {
                              return  kendo.stringify({
                                  query: options.query,
                                  variables: options.variables
                              });
                          },
                          cache: false,
                      },
                      schema: {
                          data: function (response) {
                              if (response.data.GetPurchasingStockMutationDeliveryOrder == null) {
                                  return [];
                              }
                              else {
                                  return response.data.GetPurchasingStockMutationDeliveryOrder;
                              }
                          },
                          total: function (response) {
                              if (response.data.GetPurchasingStockMutationDeliveryOrder == null) {
                                  return 0;
                              }
                              else {
                                  return response.data.GetPurchasingStockMutationDeliveryOrder.length;
                              }
                          },
                      },
                      model: {
                          fields: {
                              purchase_order_date: {type: "date"},
                          }
                      }
                  }),
              columns:  [
                  { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes: { "class": "k-text-center" },
                      template: '<div class="btn-group"> <button type="button" class="btn btn-success btn-md" id="CreateButton"> Create </button> </div>'
                  },
                  { field: "delivery_order_number", title: "Nomor DO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                  { field: "delivery_order_date", title: "Tanggal DO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes: { "class": "k-text-center" },
                      template: "#= kendo.toString(kendo.parseDate(delivery_order_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #" },
                  { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                  { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
              ],
              filterableConfig:  {
                  extra: true,
                  operators: kendo_grid.client_operator
              },
              pageableConfig: kendo_grid.default_pageable_config
          };
      },
      mounted: async function() {
          var grid = this.$refs.grid.kendoWidget();
          var createClick = this.$props.createClick;
  
          var GridElement = grid.element;
  
          GridElement.on("click", "#CreateButton", function (e){
              e.preventDefault();
              var dataItem = grid.dataItem(e.target.closest("tr"));
              
              createClick(dataItem)
          })
      },
      methods: {
         
      }
  }
  </script>
  <style scoped>
  </style>
  